import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { UiCustomisationService } from './core/services/settings/ui-customisation/ui-customisation.service';
import { UiCutomiseResponseModel } from './shared/models/ui-customisation/ui-customisation.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'one-service-hub';
  isCompleted = false;
  constructor(private uiCustomisationService: UiCustomisationService) {
    this.uiCustomisationService.getUiCustomiseData().subscribe(() => {
      this.uiCustomisationService.applyCustomTheming();
      this.isCompleted = true;
    });
  }

  ngOnInit(): void {
    this.getAndApplyLocalUiCustomisation();
    this.loadAnalyticsScript();
  }

  loadAnalyticsScript() {
    if (typeof document !== 'undefined') {
      const script = document.createElement('script');
      script.src = environment.analyticsTagUrl;
      script.async = true;
      document.body.appendChild(script);
    }
  }

  getAndApplyLocalUiCustomisation() {
    try {
      if (typeof window !== 'undefined') {
        const fetchUiCustomisationLocalStorageData = localStorage.getItem(
          'uiCustomisationConfig',
        );
        const uiCustomisationLocalStorageData =
          fetchUiCustomisationLocalStorageData
            ? (JSON.parse(
                atob(fetchUiCustomisationLocalStorageData),
              ) as UiCutomiseResponseModel)
            : null;

        if (uiCustomisationLocalStorageData) {
          this.uiCustomisationService.setUiCustomisationResponseData(
            uiCustomisationLocalStorageData,
          );
          this.isCompleted = true;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}
