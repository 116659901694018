import { Injectable } from '@angular/core';
import { FeatureModel } from '@app/shared/models/feature-management/feature.model';
import { BehaviorSubject } from 'rxjs';
import { UserDetailService } from '../user-detail/user-detail.service';

type FeatureRecord = Record<string, boolean>;
@Injectable({
  providedIn: 'root',
})
export class FeatureManagementService {
  private featuresSubject = new BehaviorSubject<FeatureRecord>({});
  features$ = this.featuresSubject.asObservable();
  featureRecord: FeatureRecord = {};

  constructor(private userDetailService: UserDetailService) {
    this.loadFeatures();
  }

  loadFeatures(): void {
    this.userDetailService.userCompactDetail.subscribe((response) => {
      if (response?.userOrganisation?.purchasedProduct?.plan?.features) {
        const featureAvailableInPlan =
          response?.userOrganisation?.purchasedProduct?.plan?.features;
        this.featureRecord = this.transformFeatures(featureAvailableInPlan);
        if (this.featureRecord) {
          localStorage.setItem(
            'featureRecords',
            btoa(JSON.stringify(this.featureRecord)),
          );
        }
        this.featuresSubject.next(this.featureRecord);
      }
    });
  }

  transformFeatures(features: FeatureModel[]): FeatureRecord {
    return features.reduce((acc: FeatureRecord, feature: FeatureModel) => {
      acc[feature.featureIdentifier] = feature.featureStatus === 'ACTIVE';
      return acc;
    }, {} as FeatureRecord);
  }

  showHideFeaturesBasedOnAvailability(featureIdentifier: string) {
    const isfeatureRecordEmpty = Object.keys(this.featureRecord).length === 0;
    const fetchedFeatureRecordLS = this.fetchFeatureRecordsFromLocalStorage();
    return !isfeatureRecordEmpty
      ? this.featureRecord[featureIdentifier] != undefined
        ? this.featureRecord[featureIdentifier]
        : true
      : fetchedFeatureRecordLS &&
        fetchedFeatureRecordLS[featureIdentifier] != undefined
      ? fetchedFeatureRecordLS[featureIdentifier]
      : true;
  }

  fetchFeatureRecordsFromLocalStorage() {
    const featureRecordsFromLocalStroage =
      localStorage.getItem('featureRecords');
    if (featureRecordsFromLocalStroage) {
      const decryptedFeatureRecords = atob(featureRecordsFromLocalStroage);
      return JSON.parse(decryptedFeatureRecords);
    }
    return null;
  }
}
