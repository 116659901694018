import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UI_CUSTOMISATION_API_CONST } from '@app/core/constants/api.constant';
import { UI_CUSTOMISATION_MSG_CONST } from '@app/core/constants/message.constant';
import { cloneDeep } from '@app/core/utils/clone-deep';
import { generateColorShade } from '@app/core/utils/generate-color-shade';
import { CustomHttpResponse } from '@app/shared/models/custom-http-response.model';
import {
  UiCustomiseActiveEnum,
  UiCustomiseComponentModel,
  UiCustomiseFileModel,
  UiCustomiseModel,
  UiCustomisePropertiesAdvancedModel,
  UiCustomiseThemeModel,
  UiCutomiseResponseModel,
} from '@app/shared/models/ui-customisation/ui-customisation.model';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiCustomisationService {
  constructor(private http: HttpClient) {}

  defaultUiCustomiseData: UiCustomiseModel | null = null;
  activeUiCustomisation: UiCustomiseActiveEnum = UiCustomiseActiveEnum.DEFAULT;

  customUiCustomiseData = new BehaviorSubject<UiCustomiseModel | null>(null);
  customUiCustomiseData$ = this.customUiCustomiseData.asObservable();

  localUiCustomiseData = new BehaviorSubject<UiCustomiseModel | null>(null);
  localUiCustomiseData$ = this.localUiCustomiseData.asObservable();

  presetColors = new BehaviorSubject<string[]>([]);
  presetColorData$ = this.presetColors.asObservable();

  backupDefault: UiCustomiseModel | null = null;
  backupCustom: UiCustomiseModel | null = null;

  getUiCustomiseDefaultData(): UiCustomiseModel | null {
    return this.defaultUiCustomiseData;
  }

  updateLocalUiData(data: UiCustomiseModel | null) {
    this.localUiCustomiseData.next(data);
  }

  updateCustomUiData(data: UiCustomiseModel | null) {
    this.customUiCustomiseData.next(data);
  }
  updatePresetColorData(newColor: string | string[]) {
    if (Array.isArray(newColor)) {
      this.presetColors.next([...newColor]);
    } else {
      const currentColors = this.presetColors.value;
      if (!currentColors.includes(newColor)) {
        const updateCurrentColors = [...currentColors, newColor];
        this.presetColors.next(updateCurrentColors);
      }
    }
  }

  getUiCustomiseData(): Observable<UiCustomiseModel | null> {
    if (this.customUiCustomiseData.getValue())
      return of(this.customUiCustomiseData.getValue());
    return this.http
      .get<CustomHttpResponse<UiCutomiseResponseModel>>(
        UI_CUSTOMISATION_API_CONST.GET_UI_CUSTOMISATION,
      )
      .pipe(
        map((res: CustomHttpResponse<UiCutomiseResponseModel>) => {
          if (res.status_code === 200) {
            // res.data = UiCustomiseNewThemeMockResponseCustomData;
            this.setUiCustomisationResponseData(res.data);
            localStorage.setItem(
              'uiCustomisationConfig',
              btoa(JSON.stringify(res.data)),
            );
          } else {
            this.customUiCustomiseData.next(null);
            this.defaultUiCustomiseData = null;
            this.localUiCustomiseData.next(null);
          }
          return this.customUiCustomiseData.getValue();
        }),
        catchError(() => {
          this.customUiCustomiseData.next(null);
          this.defaultUiCustomiseData = null;
          this.localUiCustomiseData.next(null);
          return of(this.customUiCustomiseData.getValue());
        }),
      );
  }

  setUiCustomisationResponseData(data: UiCutomiseResponseModel) {
    this.activeUiCustomisation = data.activeSetting;
    this.customUiCustomiseData.next(
      cloneDeep(data.customSetting || data.defaultSetting),
    );

    this.defaultUiCustomiseData = cloneDeep(data.defaultSetting);
    this.localUiCustomiseData.next(
      this.activeUiCustomisation === UiCustomiseActiveEnum.CUSTOM &&
        data.customSetting
        ? data.customSetting
        : data.defaultSetting,
    );

    this.backupDefault = this.prefillThemeRef(cloneDeep(data.defaultSetting));
    if (data.customSetting)
      this.backupCustom = this.prefillThemeRef(cloneDeep(data.customSetting));

    // call update component branding data
    this.updateComponentBrandingRef();
  }

  updateUiCustomisation(
    payload: UiCustomiseModel,
  ): Observable<CustomHttpResponse<UiCustomiseModel | null>> {
    return this.http
      .put<CustomHttpResponse<UiCustomiseModel>>(
        UI_CUSTOMISATION_API_CONST.UPDATE_UI_CUSTOMISATION,
        payload,
      )
      .pipe(
        map((res: CustomHttpResponse<UiCustomiseModel>) => {
          if (res.status_code === 200) {
            this.customUiCustomiseData.next(cloneDeep(res.data));
            // this.defaultUiCustomiseData = res.data;
            this.localUiCustomiseData.next(cloneDeep(res.data));
            this.activeUiCustomisation = UiCustomiseActiveEnum.CUSTOM;
            // call update component branding data
            this.updateComponentBrandingRef();
            this.applyCustomTheming();
          } else {
            this.customUiCustomiseData.next(null);
            this.defaultUiCustomiseData = null;
            this.localUiCustomiseData.next(null);
          }
          return res;
        }),
      );
  }

  /**
   * To update the component level branding value through it's reference from the themes object
   * @param previousTheme: it's required only if, you've updated the themes data. So, that it can update it's related reference value
   */
  updateComponentBrandingRef(
    previousTheme: UiCustomiseThemeModel | null = null,
  ) {
    let localBranding = this.localUiCustomiseData.getValue();
    if (localBranding) {
      localBranding = this.prefillThemeRef(localBranding, previousTheme);
      localBranding.components = { ...localBranding.components };
      this.localUiCustomiseData.next(localBranding);
    }
  }

  prefillThemeRef(
    branding: UiCustomiseModel,
    previousTheme: UiCustomiseThemeModel | null = null,
  ) {
    const themes = branding.themes;
    const components = branding.components;
    // TODO: Optimize this code
    this.getKeys(components).forEach((cKey) => {
      this.getKeys(components[cKey]).forEach((pKey) => {
        if (pKey !== 'themeRef') {
          if (!components[cKey][pKey]) {
            const fetchedRefData = this.getRefData(
              themes,
              components[cKey]['themeRef'][pKey],
            );
            components[cKey][pKey] = fetchedRefData;
          } else if (previousTheme) {
            const fetchedRefPrevData = this.getRefData(
              previousTheme,
              components[cKey]['themeRef'][pKey],
            );
            if (components[cKey][pKey] === fetchedRefPrevData) {
              const fetchedRefData = this.getRefData(
                themes,
                components[cKey]['themeRef'][pKey],
              );
              components[cKey][pKey] = fetchedRefData;
            }
          }
        }
      });
    });
    branding.components = { ...branding.components };
    return branding;
  }

  resetDefaultAndCustom(
    type: UiCustomiseActiveEnum,
  ): Observable<CustomHttpResponse<UiCustomiseModel>> {
    return this.http
      .put<CustomHttpResponse<UiCustomiseModel>>(
        UI_CUSTOMISATION_API_CONST.UPDATE_ACTIVE_UI_THEME,
        {
          activeSetting: type,
        },
      )
      .pipe(
        map((res: CustomHttpResponse<UiCustomiseModel>) => {
          if (res.status_code === 200) {
            this.activeUiCustomisation = type;
            if (type === UiCustomiseActiveEnum.DEFAULT) {
              this.defaultUiCustomiseData = cloneDeep(res.data);
            } else {
              this.customUiCustomiseData.next(cloneDeep(res.data));
            }
            this.localUiCustomiseData.next(cloneDeep(res.data));
            this.updateComponentBrandingRef();
            this.applyCustomTheming();
          }
          return res;
        }),
      );
  }

  resetAllToDefault() {
    const defaultCustomData = cloneDeep(this.defaultUiCustomiseData);
    if (defaultCustomData?.logoImage) {
      defaultCustomData.logoImage.fileContent = '';
    }
    if (defaultCustomData?.faviconImage) {
      defaultCustomData.faviconImage.fileContent = '';
    }
    this.localUiCustomiseData.next(defaultCustomData);
    this.updateComponentBrandingRef();
  }

  resetComponentLevel(cKeys: Array<keyof UiCustomiseComponentModel>) {
    const localBranding = this.localUiCustomiseData.getValue();
    if (localBranding) {
      const themes = localBranding.themes;
      const components = localBranding.components;
      cKeys.forEach((cKey) => {
        this.getKeys(localBranding.components[cKey]).forEach((pKey) => {
          if (pKey !== 'themeRef') {
            const fetchedRefData = this.getRefData(
              themes,
              components[cKey]['themeRef'][pKey],
            );
            components[cKey][pKey] = fetchedRefData;
          }
        });
      });
      localBranding.components = { ...components };
      this.localUiCustomiseData.next(localBranding);
    }
  }

  updateImageToLocal(imageContent: UiCustomiseFileModel, type: string) {
    const localCustomData: UiCustomiseModel | null = cloneDeep(
      this.localUiCustomiseData.getValue(),
    );
    if (localCustomData && type === 'LOGO') {
      localCustomData.logoImage = imageContent;
    } else if (localCustomData && type === 'FAVICON') {
      localCustomData.faviconImage = imageContent;
    }
    this.localUiCustomiseData.next(localCustomData);
    this.updateComponentBrandingRef();
  }

  resetThemeToDefault(
    themesData: UiCustomiseThemeModel,
    themeKey: keyof UiCustomiseThemeModel,
    propertyKey:
      | keyof UiCustomiseThemeModel[keyof UiCustomiseThemeModel]
      | keyof UiCustomisePropertiesAdvancedModel,
  ) {
    let localBranding = this.localUiCustomiseData.getValue();

    if (localBranding) {
      localBranding = this.prefillThemeRefForUnique(
        localBranding,
        themesData,
        themeKey,
        propertyKey,
      );
      localBranding.themes = {
        ...themesData,
      };
      localBranding.components = { ...localBranding.components };
      this.localUiCustomiseData.next(localBranding);
    }
  }

  prefillThemeRefForUnique(
    branding: UiCustomiseModel,
    previousTheme: UiCustomiseThemeModel | null = null,
    themeKey: keyof UiCustomiseThemeModel,
    propertyKey:
      | keyof UiCustomiseThemeModel[keyof UiCustomiseThemeModel]
      | keyof UiCustomisePropertiesAdvancedModel,
  ) {
    const components = branding.components;
    // TODO: Optimize this code
    this.getKeys(components).forEach((cKey) => {
      this.getKeys(components[cKey]).forEach((pKey) => {
        if (
          previousTheme &&
          pKey !== 'themeRef' &&
          components[cKey]['themeRef'][pKey] === themeKey + '.' + propertyKey
        ) {
          const fetchedRefPrevData = this.getRefData(
            previousTheme,
            components[cKey]['themeRef'][pKey],
          );
          components[cKey][pKey] = fetchedRefPrevData;
        }
      });
    });
    branding.components = { ...branding.components };
    return branding;
  }

  getKeys<T>(object: T): Array<keyof T> {
    if (object && typeof object === 'object')
      return Object.keys(object) as Array<keyof T>;
    return [];
  }

  getRefData<T>(data: T, key: string) {
    if (key) {
      const keys = key.split('.') as unknown as string[];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let ud: any = data;
      keys.forEach((k) => {
        ud = this.getRefKeyData(ud, k as keyof T);
      });
      return ud;
    } else return '';
  }

  private getRefKeyData<T>(d: T, key: keyof T) {
    return d[key] ?? '';
  }

  createCssRootVariable(pKey: string, cKey: string): string {
    const pKeyHyphenCase: string = (pKey as string)
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase() as string;
    const cKeyHyphenCase: string = (cKey as string)
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase() as string;
    return '--i-' + cKeyHyphenCase + '-' + pKeyHyphenCase;
  }

  /**
   * method is to update the root variable of the application
   */
  applyCustomTheming() {
    if (this.activeUiCustomisation === UiCustomiseActiveEnum.CUSTOM) {
      const customBranding = this.customUiCustomiseData.getValue();

      if (customBranding) {
        // for theme
        this.updateFavicon(customBranding);

        const themes = customBranding.themes;
        this.getKeys(themes).forEach((cKey) => {
          this.getKeys(themes[cKey]).forEach((pKey) => {
            const key = this.createCssRootVariable(pKey, cKey);
            document.documentElement.style.setProperty(
              key,
              themes[cKey][pKey] +
                (pKey === ('borderRadius' as unknown) ||
                pKey === ('borderWidth' as unknown)
                  ? 'px'
                  : ''),
            );

            if (cKey === 'success' && pKey === 'background') {
              const successLighterShade = generateColorShade(
                themes[cKey][pKey],
                80,
              );
              document.documentElement.style.setProperty(
                `${key}-light`,
                successLighterShade,
              );
            } else if (cKey === 'error' && pKey === 'background') {
              const successLighterShade = generateColorShade(
                themes[cKey][pKey],
                80,
              );
              document.documentElement.style.setProperty(
                `${key}-light`,
                successLighterShade,
              );
            }
          });
        });
        // for components
        const components = customBranding.components;
        this.getKeys(components).forEach((cKey) => {
          this.getKeys(components[cKey]).forEach((pKey) => {
            if (pKey !== 'themeRef') {
              if (!components[cKey][pKey]) {
                const fetchedRefData = this.getRefData(
                  themes,
                  components[cKey]['themeRef'][pKey],
                );
                components[cKey][pKey] = fetchedRefData;
              }

              const key = this.createCssRootVariable(pKey, cKey);
              document.documentElement.style.setProperty(
                key,
                components[cKey][pKey] +
                  (pKey === ('borderRadius' as unknown) ||
                  pKey === ('borderWidth' as unknown)
                    ? 'px'
                    : ''),
              );
            }
          });
        });
      }
    } else {
      if (typeof document !== 'undefined')
        document.documentElement.removeAttribute('style');
    }
  }

  getInlineStyles<T>(cKey: string, data: T): Record<string, string> {
    const styles: Record<string, string> = {};
    this.getKeys(data).forEach((pKey) => {
      if (pKey !== 'themeRef') {
        const key = this.createCssRootVariable(pKey as string, cKey);
        styles[key] = (data[pKey] +
          (pKey === ('borderRadius' as unknown) ||
          pKey === ('borderWidth' as unknown)
            ? 'px'
            : '')) as string;
      }
    });
    return styles;
  }

  updateFavicon(customBranding: UiCustomiseModel): void {
    try {
      if (typeof document !== 'undefined') {
        const faviconElement = document.getElementById('app-favicon');
        faviconElement?.setAttribute(
          'href',
          `data:image/x-icon;base64,${customBranding.faviconImage.fileContent}`,
        );
      } else {
        console.error(UI_CUSTOMISATION_MSG_CONST.ERROR_UPDATING_FAVICON);
      }
    } catch (error) {
      console.error(
        UI_CUSTOMISATION_MSG_CONST.ERROR_UPDATING_FAVICON + ':',
        error,
      );
    }
  }
}
